import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '../tools/withStyles';
import { Main } from '../components/Main';
import { Link } from '../components/Link';
import { Text } from '../components/Text';
import { Fader } from '../components/Fader';
import { Secuence } from '../components/Secuence';
import axios from 'axios'
import './style.css'

const styles = theme => ({
  root: {}
});

class Kickstarter extends React.Component {
  static propTypes = {
    classes: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: ''
    }
  }
  onNameChange(event) {
    this.setState({name: event.target.value})
  }

  onEmailChange(event) {
    this.setState({email: event.target.value})
  }

  onMessageChange(event) {
    this.setState({message: event.target.value})
  }

  handleSubmit(e){
    e.preventDefault();
    axios({
      method: "POST",
      url:"http://localhost:3002/send",
      data:  this.state
    }).then((response)=>{
      if (response.data.status === 'success') {
        alert("Message Sent.");
        this.resetForm()
      } else if (response.data.status === 'fail') {
        alert("Message failed to send.")
      }
    })
  }

  resetForm(){
    this.setState({name: "", email: "", message: ""})
  }

  render () {
    const { classes } = this.props;

    return (
      <Main className={classes.root}>
        <Secuence stagger>
          <article>
            <header>
              <h1><Text>Kickstarter</Text></h1>
            </header>
            <p><Text>We are on Kickstarter</Text></p>
            <Fader>
              <blockquote>
                <p>U.A.G.I. Team</p>
              </blockquote>
              <div>
                Click here: <a href="https://www.kickstarter.com/projects/waparty/uagi-horizon" target="_blank">Kickstarter</a>!
              </div>
            </Fader>
          </article>
        </Secuence>
      </Main>
    );
  }
}

export default withStyles(styles)(Kickstarter);
